<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Create Campaign</strong>
            </div>
            <div class="text-muted">Create new campaign</div>
          </div>
          <div class="card-body">
            <a-form :form="form" @submit.prevent="handleSubmit">
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Name">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Campaign's name.</span>
                  </template>
                  <a-input type="text" placeholder="Campaign Name" v-decorator="[ 'name', {
                  rules: [
                    { required: true, message: 'Name is required.' },
                    { max: 64, message: 'Maximum 64 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Description">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Campaign's description.</span>
                  </template>
                  <a-input placeholder="Campaign Description" v-decorator="[ 'description', {
                  rules: [
                    { max: 512, message: 'Maximum 512 characters allowed.'}
                    ]}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Clicks Daily Limit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Number of clicks allowed per day. The system will even out distribution of clicks throughout the day, ie. "day shaping". Set to 0 for unlimited clicks.</span>
                  </template>
                  <a-input type="number" min=0 placeholder="Clicks Daily Limit"
                           v-decorator="['clicks', { initialValue:0}]"/>
                </a-tooltip>
              </a-form-item>
              <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Daily Clicks per IP">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>Daily limit of clicks per IP address. Minimum limit is 1.</span>
                  </template>
                  <a-input type="number" min=5 placeholder="Daily Clicks limit per IP address"
                           v-decorator="['limit', { initialValue:5}]"/>
                </a-tooltip>
              </a-form-item>
              <div class="ant-row ant-form-item">
                <a-divider class="mt-3"></a-divider>
                <div class="ant-col ant-col-xs-24 ant-col-sm-3 ant-form-item-label"></div>
                <div class="ant-col ant-col-xs-24 ant-col-sm-18 ant-form-item-control-wrapper">
                  <button type="submit" class="btn btn-success px-5">Create Campaign</button>
                  <button type="button" @click="$router.push({ name: 'xml-campaigns' })"
                          class="btn btn-light px-5 ml-2">Cancel
                  </button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    }
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('campaign/CREATE_CAMPAIGN', {
            payload: values,
            callback: function () {
              this.$router.push({ name: 'xml-campaigns' })
            }.bind(this),
          })
        }
      })
    },
  },
}
</script>
<style lang="css" scoped>
.ant-form-item {
  margin-bottom: 5px !important;
}
</style>
